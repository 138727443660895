html {
    /* prevent centring jumps when data loads */
    overflow-y: scroll;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
/* list match input margins */
ul,
ol,
dl {
    padding-left: 15px;
}

/* style active links */
[aria-current]:not([aria-current='false']) {
    font-weight: bold;
}

.comma-list {
    display: inline;
    list-style: none;
    padding: 0;
}
.comma-list li {
    display: inline;
}
.comma-list li::after {
    content: ', ';
}
.comma-list li:last-child::after {
    content: '';
}
.status-lozenges > span {
    margin-right: 5px;
}
.status-lozenges > span:last-child {
    margin-right: 0;
}
